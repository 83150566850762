<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item>会员画像</el-breadcrumb-item>
            <el-breadcrumb-item>个人全局画像</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>姓名：</label>
                  <el-input class="width-220" v-model="param.nickName" placeholder="姓名" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>手机号：</label>
                  <el-input class="width-220" v-model="param.mobile" placeholder="手机号" clearable></el-input>
               </el-row>
               <el-button class="bg-gradient" type="primary" icon="el-icon-search" @click="handleQuery(true)" v-text="$t('msg.search')">搜索</el-button>
               <el-button  icon="el-icon-refresh-right" plain @click="handleQuery(false)" v-text="$t('msg.reset')">重置</el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <el-row class="table-box">
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  :stripe="true"
                  border fit>
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="nickName" label="姓名" min-width="50"></el-table-column>
               <el-table-column prop="mobile" label="手机号" min-width="60"></el-table-column>
               <el-table-column label="来源" min-width="50">
                  <template slot-scope="scope">
                     {{ scope.row.userSource | filterSource(that) }}
                  </template>
               </el-table-column>
               <el-table-column prop="industry" label="行业" min-width="60"></el-table-column>
               <el-table-column prop="medalName" label="等级" min-width="60"></el-table-column>
               <el-table-column label="累计订房次数" min-width="70"></el-table-column>
               <el-table-column prop="loveRoomTypeName" label="喜欢的房型"></el-table-column>
               <el-table-column label="累计订房金额"></el-table-column>
               <el-table-column label="累计商城消费次数"></el-table-column>
               <el-table-column label="累计商城消费金额"></el-table-column>
               <el-table-column prop="appletsCount" label="小程序使用次数"></el-table-column>
               <el-table-column prop="keyCount" label="智能开关操作次数"></el-table-column>
               <el-table-column prop="voiceCount" label="智能语音使用次数"></el-table-column>
               <el-table-column prop="temperature" label="喜欢的空调温度"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :currentPage="param.page" :page-size="param.limit" @handleSizeChangeSub="changePagNum" @handleCurrentChangeSub="changeCurrPage"/>
         </el-row>
      </el-row>
   </section>
</template>

<script>
import { urlObj } from '@/api/interface'
import {portrait} from "@/api/interface/data";
import { mapState } from 'vuex'
import { getDict } from "@/common/js/common";
export default {
   data(){
      return{
         tableData: [],      // 表格数据
         loading: true,      // 加载动画
         total: 0,           // 总数目数
         that: this,
         param: {
            nickName: '',
            mobile: this.mobile,
            limit: 1,
            page: 1
         }
      }
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData'])
   },
   mounted() {
      this.param.limit = sessionStorage.getItem('pageSize') * 1
      getDict(['order-source'])
      this.getMemberStatistics()
   },
   methods: {
      getMemberStatistics(){
         const url = portrait.memberStatistics + `?limit=${this.param.limit}&page=${this.param.page}`
         const param = { hotelId: this.hotelInfo.id, ...this.param }
         this.$axios.post(url, param).then(res => {
            if (res.success){
               this.loading = false
               this.total = res.total
               this.tableData = res.records
            }
         })
      },
      handleQuery(bool){
         if (bool) return this.getMemberStatistics()
         this.param.nickName = ''
         this.param.mobile = ''
      },
      // 改变每页数
      changePagNum(num){
         this.param.limit = num
         this.getMemberStatistics()
      },
      // 改变当前页
      changeCurrPage(num){
         this.param.page = num
         this.getMemberStatistics()
      }
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         oldVal && newVal.id !== oldVal.id && this.getMemberStatistics()
      }
   },
   filters: {
      filterSource(val, that){
         if (val && that.dictData['order-source']) return that.dictData['order-source'][val] || ''
      }
   }
}
</script>

<style scoped>
</style>
